import 'rxjs/add/observable/of';

import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {of} from 'rxjs';
import {Observable} from 'rxjs/Observable';

import {environment} from '../../../environments/environment';
import {User} from '../../models';
import {ProfileService} from '../auth/profile.service';
import {CommonService} from '../common/common.service';
import {NotifyService} from '../notify/notify.service';
import {ApiPaths} from './../../utils/config';
import {MongoService} from './../mongo/mongo.service';

@Injectable()
export class AuthService {
  user: Observable<User>;
  profileService: ProfileService;
  storePermissions: any;

  constructor(
    private router: Router,
    private notify: NotifyService,
    private commonService: CommonService,
    private mongoService: MongoService,
    private http: HttpClient,
  ) {
    this.user = of(this.loggedInUser());
  }

  setLoginUser(user) {
    localStorage.setItem('user', JSON.stringify(user));
    let roleInfo = JSON.parse(localStorage.getItem('user_permissions'));
    //set page load to false once one time loading is done
    //check if user loggedIn and role already stored
    if (user != null && (roleInfo == undefined || roleInfo == null || (roleInfo != undefined && roleInfo.orgId == user.orgId))) {
      //check and store user role
      user.role = user.role != undefined ? user.role : this.profileService.roleValues.Operator;
      localStorage.setItem('user_permissions', JSON.stringify({role: user.role, orgId: user.orgId}));
    } else if (user != null && (roleInfo == undefined || roleInfo == null || (roleInfo != undefined && roleInfo.orgId != user.orgId))) {
      this.checkForUserRole(user.uid, roleInfo.orgId).subscribe((orgMember: any) => {
        orgMember.length == 0
          ? this.signOut()
          : localStorage.setItem(
              'user_permissions',
              JSON.stringify({
                role: orgMember[0].role,
                orgId: orgMember[0].orgId,
              }),
            );
      });
    }

    roleInfo = JSON.parse(localStorage.getItem('user_permissions'));

    if (
      roleInfo != null &&
      this.storePermissions != undefined &&
      this.storePermissions.orgId == roleInfo.orgId &&
      this.storePermissions.role != roleInfo.role
    ) {
      //check for changes
      this.storePermissions = roleInfo;
      //redirect to dashboard
      this.commonService.reloadComponent();
    }
    //store new permissions
    this.storePermissions = roleInfo;
    this.user = Observable.of(this.loggedInUser());
  }

  checkForPermissions() {
    this.storePermissions = JSON.parse(localStorage.getItem('user_permissions'));
  }

  // If error, console log and notify user
  private handleError(error) {
    this.notify.update(error.message, 'warning');
  }

  // Email/Password Login
  emailLogin(email: string, password: string) {
    return this.mongoService
      .login({email, password})
      .toPromise()
      .then((res: any) => {
        const response = res.data;
        this.setLoginUser(response.user);
        localStorage.setItem('token', response.token);
        this.router.navigate(['/']);
      })
      .catch((error) => {
        this.handleError(error.error.error.error);
      });
  }

  // Sends email allowing user to reset password
  resetPassword(email: string) {
    let data = {
      email: email,
      reset_link: environment.redirectUrl + '/#/reset/' + btoa(email),
    };

    return this.http
      .post(ApiPaths.users.forgotPassword, data)
      .toPromise()
      .then((response: any) => {
        const result = response.data;
        if (result.isSuccess) {
          return true;
        } else {
          return false;
        }
      })
      .catch((error) => {
        this.handleError(error.error.error);
        return false;
      });
  }

  // change password
  changePassword(email: string, password: any) {
    let data = {
      email: email,
      password: password,
    };
    return this.http
      .post(ApiPaths.users.resetPassword, data)
      .toPromise()
      .then((response: any) => {
        const result = response.data;
        if (result.isSuccess) {
          return true;
        } else {
          this.handleError('Something went wrong!');
          return false;
        }
      })
      .catch((error) => {
        this.handleError(error.error.error);
        return false;
      });
  }

  // Signout
  signOut(tokenExpired = false) {
    localStorage.clear();
    this.router.navigate(['/login']);
    window.location.replace(`/login${tokenExpired ? '?token_expired=1' : ''}`);
  }

  //check for user role
  checkForUserRole(uid: string, orgId: string) {
    return this.mongoService.userRolesForOrg({uid, orgId});
  }
  //is user loggedIN
  isLoggedIn = () => {
    let token = localStorage.getItem('token');
    return token ? true : false;
  };
  //get logged in user
  loggedInUser = () => {
    const user = localStorage.getItem('user');
    return JSON.parse(user);
  };
}
