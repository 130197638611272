import {_handleError, _rejectPromise} from '../../utils/extended';
import {ApiPaths} from '../../utils/config';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';

@Injectable()
export class MongoService {
  constructor(private http: HttpClient) {}
  async addUser(data, fresh = true) {
    if (data.createdAt) delete data.createdAt;
    await this.http
      .post(fresh ? ApiPaths.users.add : ApiPaths.users.addExisting, data)
      .toPromise()
      .then(
        (res) => {},
        (err) => _rejectPromise(_handleError(err)),
      );
  }

  login(data) {
    return this.http.post(ApiPaths.users.login, data);
  }

  userRolesForOrg(data) {
    return this.http.post(ApiPaths.users.roles, data);
  }

  getAllUsers(userId = null) {
    return this.http.get(ApiPaths.users.list + (userId ? userId : ''));
  }

  getMembersInfo(memberId = null) {
    return this.http.get(ApiPaths.users.memberList + (memberId ? memberId : ''));
  }

  updateUserDetails(data: any) {
    return this.http.post(ApiPaths.users.updateUserDetails, data);
  }

  updateMemberDetails(data: any) {
    return this.http.post(ApiPaths.users.updateMemberDetails, data);
  }

  getOrgWithUsers(orgId, other = false) {
    return this.http.post(ApiPaths.org.details + orgId, {other});
  }

  updateUserData(uid, data) {
    return this.http.post(ApiPaths.users.update + uid, data);
  }

  fetchNotInOrg(orgId) {
    return this.getOrgWithUsers(orgId, true);
  }

  removeUser(data) {
    return this.http.post(ApiPaths.org.removeUser, data);
  }

  getAllOrgs(listOnly: any = false) {
    return this.http.get(ApiPaths.org.list, {params: {listOnly}});
  }

  addOrg(data) {
    return this.http.post(ApiPaths.org.create, data);
  }

  updateOrg(data) {
    return this.http.patch(ApiPaths.org.update, data);
  }

  deleteOrg(id) {
    return this.http.delete(ApiPaths.org.delete + id);
  }

  getOrgsWithRole(uid) {
    return this.http.get(ApiPaths.users.orgsWithRole + uid);
  }

  getOrgMembersByUser(userId, orgId, other = false) {
    return this.http.post(ApiPaths.org.membersByUser, {orgId, userId, other});
  }

  recordMaintenance(data) {
    return this.http.post(ApiPaths.device.recordMaintenance, data);
  }

  getAllDevices(orgId = null, listOnly: any = false) {
    return this.http.get(ApiPaths.device.list + (orgId ? orgId : ''), {params: {listOnly}});
  }

  getPaginatedDevices(offset, orgId = null, search = null) {
    if (search == '') {
      search = null;
    }
    // console.log(search)
    return this.http.get(ApiPaths.device.paginatedList + offset + '/' + orgId + '/' + search);
  }

  createDevice(data) {
    return this.http.post(ApiPaths.device.create, data);
  }

  updateDevice(data) {
    return this.http.patch(ApiPaths.device.update, data);
  }

  getDevice(serial) {
    return this.http.get(ApiPaths.device.details + serial);
  }

  removeDeviceOrg(serial) {
    return this.http.patch(ApiPaths.device.removeOrg + serial, {});
  }

  deleteDevice(id) {
    return this.http.delete(ApiPaths.device.delete + id);
  }

  getAllGroups(orgId = null, details = true) {
    return this.http.get(ApiPaths.group.list + `${details}/` + (orgId ? orgId : ''));
  }

  createGroup(data) {
    return this.http.post(ApiPaths.group.create, data);
  }

  updateGroup(data) {
    return this.http.patch(ApiPaths.group.update, data);
  }

  updateDeviceGroup(data) {
    return this.http.post(ApiPaths.group.updateDevice, data);
  }

  deleteGroup(id) {
    return this.http.delete(ApiPaths.group.delete + id);
  }

  //schedules
  getAllSchedules(scheduleId = null) {
    return this.http.get(ApiPaths.schedule.list + (scheduleId ? scheduleId : ''));
  }

  getAllSchedulesByOrg(orgId = null) {
    return this.http.get(ApiPaths.schedule.listByOrg + (orgId ? orgId : ''));
  }

  createSchedule(data: any) {
    return this.http.post(ApiPaths.schedule.create, data);
  }

  updateSchedule(data: any) {
    return this.http.patch(ApiPaths.schedule.update, data);
  }

  deleteSchedule(id) {
    return this.http.delete(ApiPaths.schedule.delete + id);
  }
  //schedules

  //notifications
  getAllNotifications(notificationId = null) {
    return this.http.get(ApiPaths.notification.list + (notificationId ? notificationId : ''));
  }

  getAllNotificationsByOrg(orgId = null) {
    return this.http.get(ApiPaths.notification.listByOrg + (orgId ? orgId : ''));
  }

  getNotificationWithItem(nItemId = null) {
    return this.http.get(ApiPaths.notification.notificationWithItem + nItemId);
  }

  getNotificationByDeviceId(deviceId = null) {
    return this.http.get(ApiPaths.notification.notificationByDeviceId + deviceId);
  }

  getNotificationByType(searchAbleId, nFor, nType) {
    return this.http.get(ApiPaths.notification.notificationByType + searchAbleId + '/' + nFor + '/' + nType);
  }

  createNotification(data: any) {
    return this.http.post(ApiPaths.notification.create, data);
  }

  updateNotification(data: any) {
    return this.http.patch(ApiPaths.notification.update, data);
  }

  updateNotificationItem(data: any) {
    return this.http.patch(ApiPaths.notification.updateNotificationItem, data);
  }

  updateNotificationLogs(data: any) {
    return this.http.post(ApiPaths.notification.createNotificationLogs, data);
  }

  deleteNotification(id) {
    return this.http.delete(ApiPaths.notification.delete + id);
  }
  //notifications

  getConnectionLogs(id) {
    return this.http.get(ApiPaths.device.connectionLogs + id).pipe(map((res: any) => res.data));
  }

  getTerminalLogs(id) {
    return this.http.get(ApiPaths.device.terminalLogs + id).pipe(map((res: any) => res.data));
  }

  sendCommand(cmd) {
    return this.http.post(ApiPaths.device.sendCommand, cmd);
  }

  getActiveAlert(alertId) {
    return this.http.get(ApiPaths.alerts.activeAlert + alertId);
  }

  getAlerts(orgId) {
    return this.http.get(ApiPaths.alerts.list + orgId);
  }

  resetAlert(data) {
    return this.http.post(ApiPaths.alerts.resetAlert, data);
  }

  recordToUpdate(data: any) {
    return this.http.post(ApiPaths.alerts.updateDashboardAlert, data);
  }
}
