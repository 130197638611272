import {Injectable} from '@angular/core';
import {uniq} from 'lodash';
import {combineLatest, Observable, of, ReplaySubject} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {Subscription} from 'rxjs/Subscription';

import {NotificationService} from '../../core/settings/notification/notification.service';
import {Command, CommandLog, Device} from '../../models';
import {ProfileService} from '../auth/profile.service';
import {MongoService} from './../mongo/mongo.service';
import {DeviceStateService} from './deviceState.service';

const deviceImages = {
  ELITE: 'assets/img/device/EliteFront.jpg',
  INFLOW: 'assets/img/device/InFlow2020-2.jpg',
  PRO: 'assets/img/device/ProFront.jpg',
  TOWER: 'assets/img/device/TowerProd1.jpg',
  ULTRA: 'assets/img/device/Ultra2B.jpg',
};

@Injectable()
export class DeviceService {
  list: Observable<Device[]>;
  orgId$ = new ReplaySubject<string>();
  org: Observable<any>;
  orgList: Observable<Device[]>;
  //start subscription for maintenance
  maintenanceSubscription: Subscription;
  // private nSubscriptions = new Subscription()
  nSubscriptions: Subscription;

  constructor(
    private profileService: ProfileService,
    private deviceStateService: DeviceStateService,
    public notificationService: NotificationService,
    private mongoService: MongoService,
  ) {
    this.orgList = this.orgId$.pipe(
      // switchMap((orgId) => afs.collection<Device>('devices', (ref) => ref.where('orgId', '==', orgId).orderBy('name')).valueChanges()),
      switchMap((orgId) => this.mongoService.getAllDevices(orgId).pipe(map((res: any) => res.data))),
      map((devices) => this.filterDevices(devices)),
    );
  }

  filterDevices(devices) {
    devices.devices.map((device) => {
      device.image = device.vers ? deviceImages[device.vers.vers_am] : 'assets/img/device/Ultra1.jpg';

      if (device.maintenance) {
        device.maintenance.sort((a, b) => {
          return a.at.seconds < b.at.seconds ? 1 : -1;
        });
      }
    });
    return devices;
  }

  //fetch all devices
  getAll(): Observable<Device[]> {
    return this.list;
  }
  //fetch device by org
  getAllByOrg(orgId): Observable<Device[]> {
    return this.mongoService.getAllDevices(orgId).pipe(
      map((res: any) => {
        const devices = res.data;
        return this.filterDevices(devices);
      }),
    );
  }
  //fetch org
  getOrg(): Observable<any> {
    return this.org;
  }
  //fetch selected device
  getDevice(id) {
    return this.mongoService.getDevice(id).pipe(
      map((res: any) => {
        const {device} = res.data;
        if (device != undefined) {
          device.image = device.vers != undefined ? deviceImages[device.vers.vers_am] : 'assets/img/device/Ultra1.jpg';
          if (device.maintenance) {
            device.maintenance.sort((a, b) => {
              return a.at.seconds < b.at.seconds ? 1 : -1;
            });
          }
        }
        return device;
      }),
    );
  }

  //fetch selected logs
  getEventLog(id): Observable<any[]> {
    return this.mongoService.getConnectionLogs(id);
  }

  //fetch terminal logs
  getTerminalLog(id): Observable<CommandLog[]> {
    return this.mongoService.getTerminalLogs(id);
  }

  updateName(serial: string, name: string) {
    return this.mongoService.updateDevice({serial, name}).subscribe();
  }

  //reset maintenance notification
  async resetNotification(event, nType) {
    let recordCalls = [];
    let searchAbleId = null;
    for (let count = 1; count <= 3; count++) {
      if (count == 1) {
        searchAbleId = event.orgId;
      } else if (count == 2) {
        searchAbleId = event.groupName;
      } else if (count == 3) {
        searchAbleId = event.serial;
      }

      if (searchAbleId != undefined) {
        //check for different levels
        this.nSubscriptions = await this.notificationService.matchNotificationIds(searchAbleId, count, nType).subscribe((nInfo) => {
          //reset all notification for selected device
          nInfo.forEach((nItem) => {
            //record calls to reset notification
            recordCalls.push(
              this.notificationService.updateNotificationItem(nItem.id, {
                sent_status: false,
                sent: 0,
                stop_snooze: false,
                last_notification_sent_at: null,
              }),
            );
          });
        });
      }
    }
    //await all
    await Promise.all(recordCalls);
    //unsubscribe all events
    // this.nSubscriptions.unsubscribe();
  }

  //update maintenance record
  updateMaintenance(event) {
    const maintenance = {...event};
    maintenance.at = new Date(event.at);
    return this.mongoService.recordMaintenance({serial: event.serial, maintenance: maintenance}).toPromise();
  }

  assignGroup(serial: string, groupName: string) {
    return this.mongoService.updateDeviceGroup({serial, name: groupName}).subscribe();
  }

  unassignGroup(serial: string) {
    return this.assignGroup(serial, '');
  }

  getAllDevices(pageOffset, orgId = null) {
    let querRef = this.mongoService.getPaginatedDevices(pageOffset, orgId);
    //fetch records
    return querRef.pipe(
      switchMap((res: any) => {
        let loadResp = res.data.devices;
        let devices: Device[] = loadResp.docs;
        const deviceIds = uniq(devices.map((device) => device.serial));

        return combineLatest(of(devices),
        // combineLatest(deviceIds.map((deviceId) => this.deviceStateService.getCurrentStateForDevice(deviceId))),
        of({limit: loadResp.limit, offset: loadResp.page, count: loadResp.totalDocs}));
      })      ,
      map(([devices,  pageInfo]) => {
        // let recordDevices = devices.map((device) => {
        //   console.log(devices,"devices")
        //   console.log(stats,"stats")
        //   console.log(pageInfo,"pageInfo")
        //   console.log(recordDevices,"recordDevices")
        //   console.log(stats.find((stat) => stat.deviceId === device.serial),"================");


        //   return {
        //     ...device,
        //     stats: stats.find((stat) => stat.deviceId === device.serial),
        //   };
        // });
        // return response with devices and paginate info
        return {
          devices: devices,
          pageInfo: pageInfo
        }
      }),
    );
  }

  // SEND COMMAND
  sendCommand(command: Command, subscribe = true): any {
    const sub = this.mongoService.sendCommand(command);
    return subscribe ? sub.subscribe() : sub;
  }
}
