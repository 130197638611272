import {Injectable} from '@angular/core';
import {Alert} from '../../models';
import {AuthService} from '../auth/auth.service';
import {NotifyService} from '../notify/notify.service';
import {ReplaySubject} from 'rxjs/ReplaySubject';
import {switchMap, map, filter} from 'rxjs/operators';
import {Observable} from 'rxjs/Observable';
import {MongoService} from './../mongo/mongo.service';

@Injectable()
export class AlertService {
  orgId$ = new ReplaySubject<string>();
  activeAlerts$: Observable<Alert[]>;
  alerts: Alert[] = [];

  list: Observable<Alert[]>;

  user = [];
  loaded = false;

  constructor(private auth: AuthService, private notify: NotifyService, private mongoService: MongoService) {
    //fetch alert by org
    this.activeAlerts$ = this.orgId$.pipe(switchMap((orgId) => this.mongoService.getAlerts(orgId).pipe(map((res: any) => res.data))));

    //get current loggedIn user details
    if (localStorage.getItem('user') != undefined && localStorage.getItem('user') != null) {
      this.user = JSON.parse(localStorage.getItem('user'));
      if (this.user) {
        this.orgId$.next(this.user['orgId']);
      }
    }
  }

  //fetch active alerts
  fetchActiveAlert() {
    return this.activeAlerts$;
  }

  //update alerts
  updateOrgAlerts(orgId) {
    this.orgId$.next(orgId);
  }

  //fetch active alerts for organization
  getActiveAlertsByOrg(orgId): Observable<Alert[]> {
    this.updateOrgAlerts(orgId);
    return this.activeAlerts$;
  }

  //fetch active alerts for device
  getActiveAlertsByDevice(deviceId): Observable<Alert[]> {
    return this.activeAlerts$.pipe(map((alerts) => alerts.filter((alert) => alert.deviceId === deviceId)));
  }
  //fetch alert
  getActiveAlertsByAlertId(alertId): Observable<Alert[]> {
    return this.mongoService.getActiveAlert(alertId).pipe(map((res: any) => res.data));
  }
  //set snoze
  recordToUpdate(alertId: string, snozeDateTime: Date) {
    return this.mongoService
      .recordToUpdate({
        alertId: alertId,
        alertInfo: {
          snoze: snozeDateTime,
        },
      })
      .toPromise();
  }
  //dismiss alert
  hideAlert(alertId, dismissReason: string) {
    // return this.afs.doc(`alerts/${alertId}`).update({
    //   dismissReason: dismissReason,
    //   closedAt: new Date(),
    //   closedBy: this.user['uid'],
    //   dismissAlert: true
    // })

    return this.mongoService
      .recordToUpdate({
        alertId: alertId,
        alertInfo: {
          dismissReason: dismissReason,
          closedAt: new Date(),
          closedBy: this.user['uid'],
          dismissAlert: true,
        },
      })
      .toPromise();
  }
  //stop snoozing
  stopSnooze(notificationId) {
    // return this.afs.doc(`notifications/${notificationId}`).update({
    //   stop_snooze: true,
    //   updated_at: new Date(),
    // });
  }
  //close alert
  closeAlert(alertId) {
    // return this.afs.doc(`alerts/${alertId}`).update({
    //   closedAt: new Date(),
    //   closedBy: this.user['uid'],
    //   isActive: false,
    // });
  }
  //close alert
  async removeOldAlerts(alertId) {
    //set info to reset
    let alertInfo = {
      closedAt: new Date(),
      closedBy: this.user['uid'],
      isActive: false,
      dismissAlert: true,
      notification_sent: true,
    };
    //call reset alert api
    return await this.mongoService
      .resetAlert({
        alertId: alertId,
        alertInfo: alertInfo,
      })
      .toPromise();
  }
}
