import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, Router} from '@angular/router';

import {AuthService} from './auth.service';
import {sectionIndexing} from '../variables';
import {ProfileService} from './profile.service';
import {TermsComplianceService} from '../terms-of-service';
import {Observable, from} from 'rxjs';
import {tap, map, take, switchMap} from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.auth.user.pipe(
      take(1),
      map((user) => !!user),
      tap((loggedIn) => {
        if (!loggedIn) {
          this.router.navigate(['/login']);
        }
      }),
    );
  }
}

@Injectable()
export class LoginGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.auth.user.pipe(
      take(1),
      map((user) => !user),
      tap((notLoggedIn) => {
        if (!notLoggedIn) {
          this.router.navigate(['/dashboard']);
        }
      }),
    );
  }
}

@Injectable()
export class ComplianceGuard implements CanActivate {
  constructor(
    private auth: AuthService,
    private profileService: ProfileService,
    private compliance: TermsComplianceService,
    private router: Router,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.auth.user.pipe(
      take(1),
      switchMap((user) => from(this.compliance.isUserCompliant(user.uid))),
      tap((isCompliant) => {
        const loggedUser = this.auth.loggedInUser();
        if (!isCompliant) {
          this.router.navigate(['/terms-of-service']);
        } else if (loggedUser.sectionIntroduceStatus == undefined || loggedUser.sectionIntroduceStatus != sectionIndexing.length) {
          this.router.navigate(['/new-features-launched']);
        }
      }),
    );
  }
}

//check for system admin permissions
@Injectable()
export class SysAdminPermissionGuard implements CanActivate {
  constructor(private profileService: ProfileService, private compliance: TermsComplianceService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let roleInfo = JSON.parse(localStorage.getItem('user_permissions'));
    //if role is for system admin then redirect to dashboard
    if (roleInfo.role == this.profileService.roleValues.SysAdmin) {
      this.router.navigate(['/dashboard']);
      return false;
    }
    return true;
  }
}
