import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {DeviceState} from '../../models';
import {MongoService} from './../mongo/mongo.service';

@Injectable()
export class DeviceStateService {
  constructor(private mongoService: MongoService) {}
  //fetch device current state
  getCurrentStateForDevice = (id: string): Observable<DeviceState> => {
    return this.mongoService.getDevice(id).pipe(map((response: any) => response.data.device.stats));
  };
}
