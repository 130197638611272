import is from 'is_js';
import * as _ from 'lodash';

export const _exists = (value) => is.existy(value) && is.not.empty(value);

export const _handleError = (err, ret = true) => {
  try {
    let errors = err.error.error.message;
    let joinErrors: any = errors;
    if (typeof errors == 'object') {
      joinErrors = '';
      // _.mapKeys(errors, (v, i) => {
      //   const e = typeof v == "object" ? v.join(", ") : v;
      //   joinErrors += `${e}`;
      //   v.length > 1 ? (joinErrors += "\n") : null;
      // });
    }
    if (ret) return joinErrors;
  } catch (e) {}
};

export const _rejectPromise = (err) => new Promise<void>((resolve, reject) => reject(err));

export const staticFilterModels = ['HYBRID 16-36', 'HYBRID 10-24', 'HYBRID 20-20'];
export const _staticFilter = (model) => staticFilterModels.includes(model);
