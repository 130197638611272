import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { TranslatorService } from '../core/translator/translator.service'
import { MenuService } from '../core/menu/menu.service'
import { SharedModule } from '../shared/shared.module'

import { menu } from './menu'
import { routes } from './routes'

import { LoginComponent } from './auth-routes/login.component'
import { ForgotComponent } from './auth-routes/forgot.component'
import { ResetComponent } from './auth-routes/reset.component';
import { StopSnoozeNotificationComponent } from './auth-routes/stop-snooze-notification/stop-snooze-notification.component';

@NgModule({
    imports: [
      SharedModule,
      RouterModule.forRoot(routes, {useHash: true}),
    ],
    declarations: [
      LoginComponent,
      ForgotComponent,
      ResetComponent,
      StopSnoozeNotificationComponent
    ],
    exports: [
      RouterModule,
    ]
})
export class RoutesModule {
  constructor(public menuService: MenuService, tr: TranslatorService) {
    menuService.addMenu(menu)
  }
}
