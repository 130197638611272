import {MongoApiService} from './mongo/mongo-api.service';
import {NgModule, Optional, SkipSelf} from '@angular/core';

import {SettingsService} from './settings/settings.service';
import {ThemesService} from './themes/themes.service';
import {TranslatorService} from './translator/translator.service';

import {AlertService} from './alerts/alert.service';
import {AuthGuard, AuthService, ComplianceGuard, SysAdminPermissionGuard, LoginGuard, ProfileService} from './auth';
import {CommandService, DeviceService, DeviceStateService, FirmwareService} from './device';
import {CommonService} from './common/common.service';
import {ManualsService} from './operating-manuals';
import {MenuService} from './menu/menu.service';
import {NotifyService} from './notify/notify.service';
import {OrgService, OrganizationDeviceStateService} from './organization';
import {TermsComplianceService, TermsService} from './terms-of-service';
import {UserService} from './user/user.service';
import {MongoService} from './mongo/mongo.service';

import {throwIfAlreadyLoaded} from './module-import-guard';

@NgModule({
  providers: [
    SettingsService,
    ThemesService,
    TranslatorService,
    MenuService,
    NotifyService,
    AuthService,
    AuthGuard,
    ComplianceGuard,
    SysAdminPermissionGuard,
    LoginGuard,
    ProfileService,
    AlertService,
    CommandService,
    CommonService,
    DeviceService,
    DeviceStateService,
    FirmwareService,
    ManualsService,
    OrgService,
    OrganizationDeviceStateService,
    TermsComplianceService,
    TermsService,
    UserService,
    MongoService,
    MongoApiService,
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
