import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {ProfileService} from '../auth/profile.service';
import {AuthService} from '../auth/auth.service';
import {CommonService} from '../common/common.service';

@Injectable({
  providedIn: 'root',
})
export class RestrictionGuard implements CanActivate {
  constructor(
    private router: Router,
    private profileService: ProfileService,
    private authService: AuthService,
    private commonService: CommonService,
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    //get current user info from localStorage
    let userInfo = JSON.parse(localStorage.getItem('user'));
    let roleInfo = JSON.parse(localStorage.getItem('user_permissions'));

    //check if current role is operator role
    if (
      userInfo != undefined &&
      userInfo.role != undefined &&
      roleInfo.orgId == userInfo.orgId &&
      userInfo.role == this.profileService.roleValues.Operator
    ) {
      this.router.navigate(['/dashboard']);
      return false;
    }

    //check if role is not for system admin and restrict if operator tries to access the page
    if (userInfo != undefined && userInfo.role != undefined && userInfo.role != this.profileService.roleValues.SysAdmin) {
      await this.authService.checkForUserRole(userInfo.uid, roleInfo.orgId).subscribe((orgMember: any) => {
        if (orgMember && orgMember.length > 0 && orgMember[0].role == this.profileService.roleValues.Operator) {
          //reset permissions
          localStorage.setItem('user_permissions', JSON.stringify({role: orgMember[0].role, orgId: orgMember[0].orgId}));
          //redirect to dashboard
          this.commonService.reloadComponent();
          return false;
        }
        return true;
      });
    }

    return true;
  }
}
