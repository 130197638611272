import { Injectable } from '@angular/core'
import { Subject } from 'rxjs/Subject'
import { ToasterService } from 'angular2-toaster'

/// Notify users about errors and other helpful stuff
export interface Msg {
  content: string
  style: string
}

@Injectable()
export class NotifyService {

  private _msgSource = new Subject<Msg>()

  msg = this._msgSource.asObservable()

  constructor(public toaster: ToasterService) { }

  update(content: string, style: string) {
    style = style == 'danger' ? 'error' : style;
    this.toaster.pop({ type: style, body: content })
    const msg: Msg = { content, style }
    this._msgSource.next(msg)
  }

  clear() {
    this._msgSource.next(null)
  }

}
