import {HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {of, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {environment} from './../../../environments/environment';
import {AuthService} from './../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class HttpConfigInterceptor implements HttpInterceptor {
  constructor(private auth: AuthService) {}
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    req = req.clone();
    if (!req.url.includes('i18n')) {
      req = req.clone({url: environment.expressUrl + req.url});
      // Get the auth token from  localstorage.
      const authToken = localStorage.getItem('token');
      // Clone the request and replace the original headers with
      if (authToken)
        req = req.clone({
          headers: req.headers.set('Authorization', authToken),
        });
      // send cloned request with header to the next handler.
    }
    return next.handle(req).pipe(
      catchError((err) => {
        if (err instanceof HttpErrorResponse && err.status == 401) this.auth.signOut(true);
        return throwError(err);
      }),
    );
  }
}
