import {Injectable} from '@angular/core';
import {Observable, ReplaySubject, of, combineLatest} from 'rxjs';
import {switchMap, map} from 'rxjs/operators';
import {Device, DeviceState} from '../../models';
import {MongoService} from '../mongo/mongo.service';

@Injectable()
export class OrganizationDeviceStateService {
  private deviceStates$: Observable<DeviceState[]>;
  private devices$ = new ReplaySubject<Device[]>();

  constructor(private mongoService: MongoService) {
    this.deviceStates$ = this.devices$.pipe(switchMap((devices) => combineLatest(devices.map((device) => this.getCurrentStateForDevice(device)))));
  }

  private getCurrentStateForDevice = (device: Device): Observable<DeviceState> =>
    this.mongoService.getDevice(device.serial).pipe(map((response: any) => response.data.device.stats));

  setDevices(devices): void {
    this.devices$.next(devices);
  }

  getOnlineDeviceStates(): Observable<DeviceState[]> {
    return this.deviceStates$;
  }
}
