import { Injectable } from '@angular/core'
import { Router } from '@angular/router'

@Injectable()
export class CommonService {

    constructor(
      private router: Router,
    ) {}

    //set delay for next process
    delay(ms: number) {
      return new Promise( resolve => setTimeout(resolve, ms) );
    }
  
    reloadComponent(orgId?: string) {
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      orgId == undefined || orgId == null ? this.router.navigate(['/dashboard']):this.router.navigate(["/dashboard/orgs/"+orgId]);
    }
}
