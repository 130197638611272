import { AuthGuard, ComplianceGuard, LoginGuard, SysAdminPermissionGuard } from '../core/auth/auth.guard'
//import system admin guard
import { SystemAdminGuard } from '../core/guard/system-admin/system-admin.guard';
import { RestrictionGuard } from '../core/guard/restriction.guard';


import { LayoutComponent } from '../layout/layout.component'
import { LoginComponent } from './auth-routes/login.component'
import { ForgotComponent } from './auth-routes/forgot.component'
import { ResetComponent } from './auth-routes/reset.component'
import { StopSnoozeNotificationComponent } from './auth-routes/stop-snooze-notification/stop-snooze-notification.component'

export const routes = [

    {
        path: '',
        component: LayoutComponent,
        canActivate: [AuthGuard],
        children: [
            { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
            { path: 'dashboard', canActivate: [ComplianceGuard], loadChildren: './dashboard/dashboard.module#DashboardModule' },
            { path: 'operating-manuals', loadChildren: './operating-manuals/operating-manuals.module#OperatingManualsModule' },
            { path: 'terms-of-service', loadChildren: './terms-of-service/terms-of-service.module#TermsOfServiceModule' },
            { path: 'new-features-launched', loadChildren: './section-introduce/section-introduce.module#SectionIntroduceModule' },
            { path: 'users', canActivate: [ComplianceGuard, RestrictionGuard, SysAdminPermissionGuard], loadChildren: './users/users.module#UsersModule' },
            { path: 'groups', canActivate: [ComplianceGuard, RestrictionGuard], loadChildren: './groups/groups.module#GroupsModule' },
            { path: 'settings', canActivate: [ComplianceGuard, RestrictionGuard], loadChildren: './settings/settings.module#SettingsModule' },
            { path: 'sysadmin', canActivate: [ComplianceGuard, SystemAdminGuard], loadChildren: './sysadmin/admin.module#AdminModule' },
        ]
    },

    { path: 'login', component: LoginComponent, canActivate: [LoginGuard] },
    { path: 'forgot', component: ForgotComponent, canActivate: [LoginGuard] },
    { path: 'reset/:encrypted_id', component: ResetComponent, canActivate: [LoginGuard] },
    { path: 'stop-reminder/:uid/:notification_item_id', component: StopSnoozeNotificationComponent },

    // Not found
    { path: '**', redirectTo: 'dashboard' }

]
