import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';

import {TermsOfService} from '../../models';
import {ProfileService} from '../auth/profile.service';
import {ApiPaths} from './../../utils/config';

@Injectable()
export class TermsService {
  constructor(private profile: ProfileService, private http: HttpClient) {}

  //fetch current active terms and services
  async fetchActiveTerms() {
    await this.http.get(ApiPaths.terms.active).subscribe((response: any) => {
      const {data} = response;
      if (data) {
        localStorage.setItem('activeTerms', JSON.stringify(data));
        localStorage.setItem('activeTermsDate', data.createdAt);
      }
    });
  }

  getActive(): TermsOfService {
    return JSON.parse(localStorage.getItem('activeTerms'));
  }

  add(data: FormData) {
    data.append('createdBy', this.profile.uid);
    return this.http.post(ApiPaths.terms.upload, data, {reportProgress: true, observe: 'events'});
  }
}

@Injectable()
export class TermsComplianceService {
  constructor(private profile: ProfileService, private termsService: TermsService, private http: HttpClient) {}

  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  //fetch active terms
  async getComplianceDoc(userId: string) {
    // TODO make dynamic this later
    await this.termsService.fetchActiveTerms();
    await this.delay(2000);
    const activeTerms = this.termsService.getActive();
    const response: any = await this.http.get(ApiPaths.terms.get_compliance + `${userId}/${activeTerms.id}`).toPromise();
    return response.data;
  }

  async isUserCompliant(userId: string): Promise<boolean> {
    const complianceDoc: any = await this.getComplianceDoc(userId);
    return !!complianceDoc && complianceDoc.isAcknowledged;
  }

  addCompliance(userId: string, termsOfServiceId: string) {
    const data = {
      createdBy: this.profile.uid,
      isAcknowledged: true,
      termsOfServiceId,
      userId,
    };
    return this.http.post(ApiPaths.terms.update_compliance, data);
  }
}
