import {Injectable} from '@angular/core';
import {AuthService} from '../auth/auth.service';
import {ReplaySubject} from 'rxjs';
import {$} from 'protractor';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  user$ = new ReplaySubject<any>();
  user = null;
  roles = ['Operator', 'Manager', 'Org Owner', 'SysAdmin'];

  defaultRoles = ['Operator', 'Manager', 'Org Owner'];
  //set roles
  roleValues = {
    Operator: 0,
    Manager: 1,
    OrgOwner: 2,
    SysAdmin: 3,
  };
  //set logs typs
  notificationlogType = {
    ALERT: 0,
    STOP: 1,
    EDIT: 2,
    DELETE: 3,
  };

  constructor(private auth: AuthService) {
    //check for current loggedIn user details
    this.auth.user.subscribe((user) => {
      if (user) {
        this.user = user;
        this.user$.next(user);
      }
    });
  }

  updateUser(user) {
    let currentUser = JSON.parse(localStorage.getItem('user'));
    let updatedUser = user;
    currentUser.firstName = updatedUser.firstName;
    currentUser.lastName = updatedUser.lastName;
    currentUser.title = updatedUser.title;
    localStorage.setItem('user', JSON.stringify(currentUser));
    this.user = currentUser;
    this.user$.next(currentUser);

    // update using JS
    document.getElementById('sidebar_name').innerHTML = this.fullName;
    document.getElementById('sidebar_title').innerHTML = this.title;
  }

  //set default props of loggedIn user
  get fullName() {
    return this.user ? `${this.user.firstName} ${this.user.lastName}` : '';
  }
  get title() {
    return this.user ? this.user.title : '';
  }
  get org() {
    return this.user ? this.user.orgId : '';
  }
  get uid() {
    return this.user ? this.user.uid : '';
  }
  get role() {
    return this.user ? this.user.role : '';
  }
  get roleName() {
    return this.user ? this.roles[this.user.role] : '';
  }
}
