import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {ProfileService} from '../../../core/auth';
import {MongoService} from '../../../core/mongo/mongo.service';

@Component({
  selector: 'app-admin-existing-user-add',
  templateUrl: './existing-user-add.component.html',
})
export class ExistingUserAddComponent implements OnInit {
  form: FormGroup;
  @Input() isUpdating: boolean;
  @Input() allUser: any;
  @Input() listRoles: any;
  @Input() uid?: any;
  @Output() data = new EventEmitter<object>();
  @Output() cancel = new EventEmitter<object>();

  private _orgId?: any;
  @Input() set orgId(value: string) {
    this._orgId = value;
    this.loadMembers();
  }
  loadingIndicator: boolean = false;

  constructor(public fb: FormBuilder, public profileService: ProfileService, public mongoService: MongoService) {}

  ngOnInit() {
    //set form
    this.form = this.fb.group({
      user_id: ['', [Validators.required]],
      role: ['', [Validators.required]],
    });
  }

  loadMembers() {
    if (this._orgId) {
      this.loadingIndicator = true;
      let sub = this.mongoService.getOrgWithUsers(this._orgId, true);
      if (this.uid) sub = this.mongoService.getOrgMembersByUser(this.uid, this._orgId, true);
      sub.subscribe((response: any) => {
        const {data} = response;
        this.loadingIndicator = false;
        this.allUser = data.users;
      });
    }
  }

  get user_id() {
    return this.form.get('user_id');
  }
  get role() {
    return this.form.get('role');
  }

  submit() {
    let selectedUser = this.allUser.find((u) => u.uid == this.form.value.user_id);
    this.data.emit({...this.form.value, selectedUser});
    this.form.reset();
  }

  close(event) {
    this.cancel.emit({...event});
  }
}
