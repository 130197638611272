import {DeviceService} from './device.service';
import {Injectable} from '@angular/core';
import {Command} from '../../models';

@Injectable()
export class CommandService {
  constructor(private deviceService: DeviceService) {}
  //add command to queue
  send(command: Command, subscribe = true) {
    return this.deviceService.sendCommand(command, subscribe);
  }
}
