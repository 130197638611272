import { Component, OnInit } from '@angular/core'

import { UserblockService } from './userblock.service'
import { ProfileService } from '../../../core/auth/profile.service'

@Component({
    selector: 'app-userblock',
    templateUrl: './userblock.component.html',
    styleUrls: ['./userblock.component.scss']
})
export class UserblockComponent implements OnInit {
    user: any
    constructor(
      public userblockService: UserblockService,
      public profile: ProfileService,
    ) {
      this.user = {
        picture: 'assets/img/user/14.jpg'
      }
    }

    ngOnInit() {}

    userBlockIsVisible() {
      return this.userblockService.getVisibility()
    }

}
