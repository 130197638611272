import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {ProfileService} from '../../../core/auth';
import {NotificationItems, Notifications} from '../../../models';
import {MongoService} from '../../mongo/mongo.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  list: Observable<Notifications[]>;
  list2: Observable<NotificationItems[]>;

  constructor(private profile: ProfileService, private mongoService: MongoService) {}
  //fetch all notifications
  getAllNotifications(notificationId = null): Observable<Notifications[]> {
    // return this.list
    return this.mongoService.getAllNotifications(notificationId).pipe(map((res: any) => res.data));
  }

  //get all notification by org
  getAllNotificationByOrg(orgId: string): Observable<any[]> {
    return this.mongoService.getAllNotificationsByOrg(orgId).pipe(map((res: any) => res.data));
  }

  //get notification item
  getNotificationItem(nItemId: string): Observable<any> {
    // return this.collection2.doc<NotificationItems>(nItemId).valueChanges()
    return this.mongoService.getNotificationWithItem(nItemId).pipe(map((res: any) => res.data));
  }

  //notifications by notification id
  fetchNItemsByDevice(deviceId): Observable<any[]> {
    return this.mongoService.getNotificationByDeviceId(deviceId).pipe(map((res: any) => res.data));
  }

  //fetch notification by notification created ids
  matchNotificationIds(matchId, nFor, nType): Observable<any[]> {
    return this.mongoService.getNotificationByType(matchId, nFor, nType).pipe(map((res: any) => res.data));
  }

  //create notification
  async add(item: Notifications, notificationItem = [], itemType): Promise<void> {
    const response: any = await this.mongoService
      .createNotification({
        notificationInfo: item,
        notificationItem: notificationItem,
      })
      .toPromise();

    return response.data.id;
  }

  //update notification
  async updateNotification(notificationId: string, item: any, notificationItem = [], nInfo: any) {
    const response: any = await this.mongoService
      .updateNotification({
        notificationId: notificationId,
        notificationInfo: item,
        notificationItem: notificationItem,
      })
      .toPromise();

    //store notification logs
    this.createNotificationLogs({
      log_type: this.profile.notificationlogType.EDIT,
      notification_id: notificationId,
      uid: this.profile.uid,
      before_edit: nInfo,
      after_edit: item,
      message: 'Notification Updated.',
    });

    return response.data.id;
  }

  //update notification item
  updateNotificationItem(nItemId: string, item: any) {
    this.mongoService
      .updateNotificationItem({
        nItemId: nItemId,
        itemInfo: item,
      })
      .toPromise();
  }
  //create notification logs
  createNotificationLogs(item: any) {
    return this.mongoService.updateNotificationLogs(item).toPromise();
  }
  //remove notification
  deleteNotification(notificationId: string) {
    return this.mongoService.deleteNotification(notificationId).toPromise();
  }
}
