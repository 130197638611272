import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {OrgMembers, User} from '../../models';
import {AuthService, ProfileService} from '../auth';
import {NotifyService} from '../notify/notify.service';
import {ApiPaths} from './../../utils/config';
import {MongoService} from './../mongo/mongo.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  list: Observable<User[]>;

  memberList: Observable<OrgMembers[]>;

  filteredUsers = [];

  constructor(
    private auth: AuthService,
    private profile: ProfileService,
    private notify: NotifyService,
    private mongoService: MongoService,
    private http: HttpClient,
  ) {}
  //all users
  getAll(): Observable<User[]> {
    return this.mongoService.getAllUsers().pipe(map((res: any) => res.data));
  }

  //create user
  async add(item: User) {
    item.receivesAlerts = false;
    return this.mongoService.addUser(item);
  }

  // If error, console log and notify user
  private handleError(error) {
    this.notify.update(error.message, 'warning');
  }

  //update member orgId
  async updateMemberOrgId(itemId: string, orgId: string, role: number) {
    await this.mongoService
      .updateMemberDetails({
        memberId: itemId,
        memberInfo: {orgId: orgId, role: role},
      })
      .toPromise();
  }
  //update user profile
  async updateProfile(item: any, uuid: string) {
    await this.mongoService
      .updateUserDetails({
        uid: uuid,
        userInfo: item,
      })
      .toPromise();
  }
  //check if section viewed if not then store
  checkIfSectionViewed(sectionIndex: number) {
    let sectionViewed = this.auth.loggedInUser().sectionViewed == undefined ? [] : this.auth.loggedInUser().sectionViewed;
    if (sectionViewed.indexOf(sectionIndex) == -1) {
      sectionViewed.push(sectionIndex);
      this.update({
        uid: this.profile.uid,
        sectionViewed: sectionViewed,
      });
      let userInfo = this.auth.loggedInUser();
      userInfo['sectionViewed'] = sectionViewed;
      this.auth.setLoginUser(userInfo);
    }
  }

  async update(item: any) {
    item.updatedBy = this.profile.uid;
    // this.collection.doc(item.uid).update(item);
    this.mongoService.updateUserData(this.profile.uid, item).subscribe((res: any) => this.auth.setLoginUser(res.data));
  }

  //add new member from already existed members
  addMember(item: OrgMembers): Promise<void> {
    return this.mongoService.addUser(item, false);
  }

  //fetch extra added members
  getAddedMembers(memberId = null): Observable<OrgMembers[]> {
    // return this.memberList;
    return this.mongoService.getMembersInfo(memberId).pipe(map((res: any) => res.data));
  }

  //update role
  async updateMemberRole(itemId: string, role: number) {
    // return this.memberCollection.doc(itemId).update({role: role});
    await this.mongoService
      .updateMemberDetails({
        memberId: itemId,
        memberInfo: {role: role},
      })
      .toPromise();
  }

  //resend invitation email
  async resendInvitationEmail(uData: any) {
    return await this.http
      .post(ApiPaths.users.resendInvite, {uid: uData.uid, email: uData.email})
      .toPromise()
      .then((result: any) => {
        if (result.isSuccess) {
          return true;
        } else {
          return false;
        }
      })
      .catch((error) => {
        this.handleError(error);
        return false;
      });
  }
}
