import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { ProfileService } from '../../auth/profile.service';

@Injectable({
  providedIn: 'root'
})
export class SystemAdminGuard implements CanActivate {
  
  constructor(
    private router: Router,
    private profileService: ProfileService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      //get current user info from localStorage
      let userInfo = JSON.parse(localStorage.getItem('user'));
      //if role is for normal user then redirect to dashboard
      if(userInfo != undefined && userInfo.role != undefined && userInfo.role != this.profileService.roleValues.SysAdmin) {
        this.router.navigate(['/dashboard']);
        return false;
      }

      return true;
      
  }
  
}
