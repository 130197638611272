import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {ProfileService} from '../../../../core/auth';
import {NotifyService} from '../../../../core/notify/notify.service';

@Component({
  selector: 'app-admin-user-add',
  templateUrl: './user-add.component.html',
  providers: [ProfileService],
})
export class UserAddComponent implements OnInit {
  form: FormGroup;
  @Input() orgId: String;
  @Input() isUpdating: boolean;
  @Input() userInfo: any;
  @Input() listRoles: any;
  @Output() data = new EventEmitter<object>();
  @Output() cancelRequest = new EventEmitter();

  roleInfo = JSON.parse(localStorage.getItem('user_permissions'));
  roles = [];
  userForm = {
    email: '',
    country_code: null,
    phone: null,
    firstName: '',
    lastName: '',
    title: '',
    role: 0,
    memberInfo: '',
  };

  constructor(private notifyService: NotifyService, public fb: FormBuilder, public profileService: ProfileService) {}

  ngOnInit() {
    //set roles
    this.roles = this.profileService.roles.slice(0, this.profileService.role + 1);

    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      country_code: ['', [Validators.pattern(/^[0-9]\d*$/)]],
      phone: ['', [Validators.pattern(/^[0-9]\d*$/)]],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      role: ['', [Validators.required]],
      title: '',
    });

    //set user values
    if (this.userInfo != undefined) {
      this._setUserInfo();
    }
  }

  //set user info
  _setUserInfo() {
    this.userForm.email = this.userInfo.email;
    this.userForm.country_code =
      this.userInfo.country_code != undefined && this.userInfo.country_code != '' ? parseInt(this.userInfo.country_code) : null;
    this.userForm.phone = this.userInfo.phone != undefined && this.userInfo.phone != '' ? parseInt(this.userInfo.phone) : null;
    this.userForm.firstName = this.userInfo.firstName;
    this.userForm.lastName = this.userInfo.lastName;
    this.userForm.title = this.userInfo.title != undefined ? this.userInfo.title : '';
    this.userForm.role = this.userInfo.memberInfo != undefined ? parseInt(this.userInfo.memberInfo.role) : parseInt(this.userInfo.role);
    this.userForm.memberInfo = this.userInfo.memberInfo != undefined ? this.userInfo.memberInfo : null;
  }

  get email() {
    return this.form.get('email');
  }
  get country_code() {
    return this.form.get('country_code');
  }
  get phone() {
    return this.form.get('phone');
  }
  get firstName() {
    return this.form.get('firstName');
  }
  get lastName() {
    return this.form.get('lastName');
  }
  get title() {
    return this.form.get('title');
  }
  get role() {
    return this.form.get('role');
  }

  //check for validation if phone or country code filled up
  checkForValidation() {
    let phoneControl = this.form.get('phone');
    let countryCodeControl = this.form.get('country_code');

    if (
      (this.form.value.phone == '' || this.form.value.phone == null) &&
      (this.form.value.country_code == '' || this.form.value.country_code == null)
    ) {
      phoneControl.setValidators([]);
      phoneControl.updateValueAndValidity();

      countryCodeControl.setValidators([]);
      countryCodeControl.updateValueAndValidity();
    } else {
      countryCodeControl.setValidators([Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)]);
      phoneControl.setValidators([Validators.required, Validators.pattern('[0-9 ]{10}')]);
    }
  }

  _validatePhoneNumber() {
    let validate = true;
    if (
      this.form.value.phone != '' &&
      this.form.value.phone != null &&
      (this.form.value.country_code == '' || this.form.value.country_code == null)
    ) {
      validate = false;
      this.notifyService.update(`Country code is required!`, 'warning');
    } else if (
      (this.form.value.phone == '' || this.form.value.phone == null) &&
      this.form.value.country_code != '' &&
      this.form.value.country_code != null
    ) {
      validate = false;
      this.notifyService.update(`Phone number is required!`, 'warning');
    }
    if (this.form.value.role === '' || this.form.value.role == null || isNaN(this.form.value.role)) {
      validate = false;
      this.notifyService.update(`User role is required!`, 'warning');
    }
    return validate;
  }

  submit() {
    //validate phone number and country code
    if (!this._validatePhoneNumber()) {
      return false;
    }
    this.data.emit({...this.form.value, orgId: this.orgId, memberInfo: this.userForm.memberInfo});
    // this.form.reset()
  }

  cancel(event) {
    event.preventDefault();
    this.cancelRequest.emit();
    this.form.reset();
  }
}
