import { Injectable } from '@angular/core'
import { ProfileService } from '../../core/auth/profile.service'

@Injectable()
export class MenuService {

    menuItems: Array<any>

    constructor(
      public profile: ProfileService,
    ) {
        this.menuItems = []
    }

    addMenu(items: Array<{
        text: string,
        heading?: boolean,
        link?: string,     // internal route links
        elink?: string,    // used only for external links
        target?: string,   // anchor target="_blank|_self|_parent|_top|framename"
        icon?: string,
        role?: number,
        alert?: string,
        submenu?: Array<any>
    }>) {
        items.forEach((item) => {
          this.menuItems.push(item)
        })
    }

    getMenu(access = 0) {
      return this.menuItems.filter(item => this.profile.roleValues.SysAdmin == access 
        ? (!item.role || item.role <= access) && item.systemAdminHide == false
        : !item.role || item.role <= access
        )
    }

}
