import {MongoApiService} from './../mongo/mongo-api.service';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';

import {ProfileService} from '../auth/profile.service';
import {Firmware, FirmwareFile} from '../../models';
import {map} from 'rxjs/operators';

@Injectable()
export class FirmwareService {
  constructor(private profile: ProfileService, private mongoApiService: MongoApiService) {}

  //fetch all
  getAll(): Observable<Array<Firmware>> {
    return this.mongoApiService.getAllFirmwares().pipe(map((res: any) => res.data));
  }

  //fetch firmware
  getFirmware(firmwareId: string): Observable<Firmware> {
    return this.mongoApiService.getFirmware(firmwareId).pipe(map((res: any) => res.data));
  }

  //add new firmware
  add(firmware: Firmware, image1: FirmwareFile, image2: FirmwareFile) {
    firmware.createdBy = this.profile.uid;
    firmware.image1 = image1;
    firmware.image2 = image2;

    return this.mongoApiService.createFirmware(firmware);
  }
  //update firmware
  updateFirmware(firmwareId: string, data: any) {
    return this.mongoApiService.updateFirmware(firmwareId, data);
  }
  //remove firmware
  delete(firmwareId: string) {
    return this.mongoApiService.deleteFirmware(firmwareId);
  }
}
