
const Dashboard = {
  text: 'Dashboard',
  link: '/dashboard',
  icon: 'icon-speedometer',
  role: 0,
  systemAdminHide: false
}

const OperatingManuals = {
  text: 'Operating Manuals',
  link: '/operating-manuals',
  icon: 'icon-notebook',
  role: 0,
  systemAdminHide: false
}

const TermsOfService = {
  text: 'Terms Of Service',
  link: '/terms-of-service',
  icon: 'icon-doc',
  role: 0,
  systemAdminHide: false
}

const Users = {
  text: 'Users',
  link: '/users',
  icon: 'icon-layers',
  role: 1,
  systemAdminHide: true
}

const Groups = {
  text: 'Groups',
  link: '/groups',
  icon: 'icon-globe',
  role: 1,
  systemAdminHide: false
}

const Schedules = {
  text: 'Schedules',
  link: '/settings/schedules',
  icon: 'icon-clock',
  role: 1,
  systemAdminHide: false
}

const Notifications = {
  text: 'Notifications',
  link: '/settings/notifications',
  icon: 'icon-bell',
  role: 1,
  systemAdminHide: false
}

const OrgSettings = {
  text: 'Settings2',
  link: '/settings2',
  icon: 'icon-settings',
  role: 0,
  systemAdminHide: false
}

const Admin = {
  text: 'System Admin',
  link: '/sysadmin',
  icon: 'icon-user',
  role: 3,
  systemAdminHide: false,
  submenu: [
    {
      text: 'Organizations',
      link: '/sysadmin/organizations'
    },
    {
      text: 'Devices',
      link: '/sysadmin/devices'
    },
    {
      text: 'Firmware',
      link: '/sysadmin/firmware'
    }
  ]
}

const headingMain = {
    text: 'Main Navigation',
    heading: true
}

export const menu = [
    headingMain,
    Dashboard,
    OperatingManuals,
    TermsOfService,
    Users,
    Groups,
    // OrgSettings,
    Schedules,
    Notifications,
    Admin,
]
