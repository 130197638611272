import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ApiPaths} from '../../utils/config';
import {environment} from '../../../environments/environment';

@Injectable()
export class MongoApiService {
  constructor(private http: HttpClient) {}

  getManuals() {
    return this.http.get(ApiPaths.manuals.list);
  }

  add(data) {
    return this.http.post(ApiPaths.manuals.upload, data);
  }

  deleteFile(data) {
    return this.http.post(ApiPaths.manuals.delete, data);
  }

  _downloadUrl(fileName, view = false) {
    return environment.expressUrl + ApiPaths.download + fileName + `${view ? '?static=true' : ''}`;
  }

  // firmware methods
  getAllFirmwares(orgId = null) {
    return this.http.get(ApiPaths.firmware.list + (orgId ? orgId : ''));
  }

  getFirmware(id = null) {
    return this.http.get(ApiPaths.firmware.details + id);
  }

  createFirmware(data) {
    return this.http.post(ApiPaths.firmware.create, data);
  }

  updateFirmware(id, data) {
    return this.http.patch(ApiPaths.firmware.update + id, data);
  }

  deleteFirmware(id) {
    return this.http.delete(ApiPaths.firmware.delete + id);
  }
}
