import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Organization} from '../../models';
import {MongoService} from './../mongo/mongo.service';

@Injectable()
export class OrgService {
  list: Observable<Organization[]>;

  constructor(private mongoService: MongoService) {}
  //fetch all organization
  getAll(): Observable<Organization[]> {
    return this.mongoService.getAllOrgs().pipe(
      map((res: any) => {
        const orgs: Organization[] = res.data;
        return orgs.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
      }),
    );
  }

  //fetch org
  fetchOrganization(orgId): Observable<any[]> {
    return this.mongoService.getOrgWithUsers(orgId).pipe(
      map((res: any) => {
        return res.data.org ? [res.data.org] : [];
      }),
    );
  }
}
