export const ApiPaths = {
  users: {
    list: 'users/list',
    memberList: 'users/member-list',
    updateUserDetails: 'users/update-user-details',
    updateMemberDetails: 'users/update-member-details',
    add: 'users/register',
    login: 'users/login',
    update: 'users/update/',
    resendInvite: 'users/resend-invitation',
    forgotPassword: 'users/forgot-password',
    resetPassword: 'users/reset-password',
    addExisting: 'users/add_existing',
    roles: 'users/org_roles',
    orgsWithRole: 'users/orgs_with_role/',
  },
  org: {
    list: 'org/list',
    create: 'org/create',
    update: 'org/update',
    details: 'org/details/',
    removeUser: 'org/remove_user',
    delete: 'org/delete/',
    membersByUser: 'org/memebers_by_user',
  },
  device: {
    list: 'devices/list/',
    paginatedList: 'devices/paginated-list/',
    create: 'devices/create',
    update: 'devices/update',
    details: 'devices/details/',
    delete: 'devices/delete/',
    removeOrg: 'devices/remove_org/',
    connectionLogs: 'devices/connection_logs/',
    terminalLogs: 'devices/terminal_logs/',
    sendCommand: 'devices/send_command',
    recordMaintenance: 'devices/record-maintenance',
  },
  group: {
    list: 'groups/list/',
    create: 'groups/create',
    update: 'groups/update',
    updateDevice: 'groups/update_device',
    delete: 'groups/delete/',
  },
  schedule: {
    list: 'schedule/list/',
    listByOrg: 'schedule/list-by-org/',
    create: 'schedule/create',
    update: 'schedule/update',
    delete: 'schedule/delete/',
  },
  notification: {
    list: 'notification/list/',
    listByOrg: 'notification/list-by-org/',
    notificationWithItem: 'notification/notification-with-item/',
    notificationByDeviceId: 'notification/notification-by-device-id/',
    notificationByType: 'notification/notification-by-type/',
    create: 'notification/create',
    update: 'notification/update',
    updateNotificationItem: 'notification/update-notification-item',
    createNotificationLogs: 'notification/create-notification-logs',
    delete: 'notification/delete/',
  },
  manuals: {
    list: 'storage/manuals',
    upload: 'storage/manual',
    delete: 'storage/delete',
  },
  terms: {
    active: 'storage/terms',
    upload: 'storage/terms',
    delete: 'storage/delete_terms',
    get_compliance: 'storage/compliance/',
    update_compliance: 'storage/compliance',
  },
  download: 'storage/download/',
  firmware: {
    list: 'firmware/list/',
    details: 'firmware/details/',
    create: 'firmware/create',
    update: 'firmware/update/',
    delete: 'firmware/delete/',
  },
  alerts: {
    list: 'alerts/list/',
    activeAlert: 'alerts/active-alert/',
    resetAlert: 'alerts/reset-alert',
    updateDashboardAlert: 'alerts/update-dashboard-alert',
  },
};

export const requestTime = 30 * 1000;
export const validationPatterns = {
  noSpace: /^\S*$/,
  noStartEndSpaces: /^(?! )[A-Za-z0-9 ]*(?<! )$/,
};
