import { Component, OnInit } from '@angular/core'
import { FormBuilder } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { Subscription } from 'rxjs'
import { AlertService } from '../../../core/alerts/alert.service'
import { NotificationService } from '../../../core/settings/notification/notification.service'
import { NotifyService } from '../../../core/notify/notify.service'

@Component({
  selector: 'app-stop-snooze-notification',
  templateUrl: './stop-snooze-notification.component.html',
  styles: ['']
})
export class StopSnoozeNotificationComponent implements OnInit {

  sub: Subscription

  constructor(
    public fb: FormBuilder,
    public alertService: AlertService,
    public notificationService: NotificationService,
    public route: ActivatedRoute,
    private router: Router,
    private notify: NotifyService,
  ) { }

  ngOnInit() {
    //call for snooze function
    this._stopSnoozing();
  }

  async _stopSnoozing() {
    //stop further snoozing
    try {
      //update status
      this.notificationService.updateNotificationItem(this.route.snapshot.paramMap.get("notification_item_id"), {stop_snooze: true});
      //fetch notification info
      this.notificationService.getNotificationItem(this.route.snapshot.paramMap.get("notification_item_id")).subscribe(async nItem => {
        //if notification found
        if(nItem != undefined && nItem.notification != undefined) {
          //get notification
          // await this.notificationService.getAllNotifications(nItem.notification_id).subscribe(async nInfo => {
            //if notification found
            // if(nInfo != undefined && nInfo['notifications'][0] != undefined) {
              //store log
              await this.notificationService.createNotificationLogs({
                log_type: 1,
                notification_id: nItem.notification.id,
                notification_type: nItem.notification.notification_type,
                notification_type_val: nItem.notification.notification_type_val,
                notified_by: nItem.notification.notified_by,
                device_id: nItem.device_id,
                uid: this.route.snapshot.paramMap.get("uid"),
                message: "Notification Dissmissed."
              })
            // }
          // })
        }
      })
      this.notify.update("Notification reminder has been stopped!", 'success')
      //redirect login
      this.router.navigate(['/login'])

    } catch (err) {
      console.log(err)
      this.notify.update("Something went wrong!", 'error')
      //redirect login
      // this.router.navigate(['/login'])
    }
  }

}
